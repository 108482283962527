<template>
  <b-card
    class="inventoryorderdetailextend-edit-wrapper"
  >
    <!-- form -->
    <ValidationObserver ref="observer">
    <b-form id="inventoryorderdetailextendForm" class="edit-form mt-2">
      <b-row>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="库存盘点编号"
            label-for="inventory_detailid"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inventory_detailid"
              size="sm"
              v-model="inventoryorderdetailextend.inventory_detailid"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出入库类型"
            label-for="type"
            label-size="sm"
            class="mb-1"
          >
            <v-select
            id="extendType"
            :options="typeOptions"
            :clearable="false"
            v-model="typeName"
            class="select-size-sm"
            @input="changeType($event)"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出入库单ID"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              disabled
              v-model="inventoryorderdetailextend.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="出入库通知单号"
            label-for="order_no"
            label-size="sm"
            :class="'mb-1 '+ required"
          >
            <ValidationProvider :rules="required" name="出入库通知单号" #default="{ errors }">
              <b-form-input
                id="order_no"
                size="sm"
                @blur="blurInOutBoundNo"
                v-model="inventoryorderdetailextend.order_no"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="数量"
            label-for="qty"
            label-size="sm"
            :class="'mb-1 '+ required"
          >
            <ValidationProvider :rules="required" name="数量" #default="{ errors }">
              <b-form-input
                id="qty"
                size="sm"
                @keyup.native="btKeyUp"
                v-model="inventoryorderdetailextend.qty"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label-cols="1"
            label-cols-lg="1"
            label="备注"
            label-for="memo"
            label-size="sm"
            class="mb-1"
          >
            <b-form-textarea
                    id="remark"
                    v-model="inventoryorderdetailextend.memo"
                    rows="3"
                    max-rows="6"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="附件"
              label-for="attachments"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload  v-if="inventoryorderdetailextend.loaded" :theme="'files'"
                               :attachment_id="'attachments'"
                               :id="inventoryorderdetailextend.attachments"
                               :object_type="'inventoryOrderDetailExtend'"
                               :object_id="inventoryorderdetailextend.extend_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </ValidationObserver>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import inventoryorderdetailextendStore from './inventoryorderdetailextendStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import axios from "@/libs/axios";

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      inventoryorderdetailextend: ref({}),
      inventory_detailid: '',
      typeOptions: [ { "value": "0", "label": "入库" },
                     { "value": "1", "label": "出库" } ],
      typeName: '',
      required: "required",
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('inventoryorderdetailextend')) store.registerModule('inventoryorderdetailextend', inventoryorderdetailextendStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inventoryorderdetailextend')) store.unregisterModule('inventoryorderdetailextend')
    })

    const edit = function() {
      store.dispatch('inventoryorderdetailextend/edit', {id: this.id}).then(res => {
        this.inventoryorderdetailextend = res.data.data
        for (let i = 0; i < this.typeOptions.length; i++) {
          if (this.typeOptions[i].value == this.inventoryorderdetailextend.type) {
            this.typeName = this.typeOptions[i].label
          }
        }
        if (this.id === 0) {
          this.inventoryorderdetailextend.inventory_detailid = this.inventory_detailid
        }
      })
    }

    const view = function() {
      store.dispatch('inventoryorderdetailextend/view', {id: this.id}).then(res => {
        this.inventoryorderdetailextend = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = async function() {
      let validate = await this.$refs.observer.validate()

      if (this.inventoryorderdetailextend.order_no === undefined){
        toast.error("入库或出库通知单编号不能为空！")
        return
      }

      if (validate) {
        axios.get('/api/inventoryorderdetailextend/save', {params: this.inventoryorderdetailextend}).then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
            this.$router.go(-1)
          }else {
            toast.error('数据保存异常，'+ res.data.data)
          }
        })
      } else {
        toast.error('请正确填写字段')
      }
    }

    const onUploaded = function (id, attachment, result) {
      this.inventoryorderdetailextend[id] = result
    }

    const blurInOutBoundNo = async function(v) {
      if (this.inventoryorderdetailextend.type === undefined) {
        this.inventoryorderdetailextend.order_no = null
        toast.error("请先选择入库还是出库!")
        return
      }
      else {
        let order_noS = this.inventoryorderdetailextend.order_no;
        const paramss = {
          order_no:order_noS
        }
        await axios.get("/api/inventoryorderdetailextend/search", {params : paramss }).then(res => {
            if(res.data.data.ext.totals>0){
              toast.error("未找到该入库、出库通知单号或者通知单已存在！")
              this.$set(this.inventoryorderdetailextend,"order_id", '')
              this.$set(this.inventoryorderdetailextend,"order_no", '')
            }else{
              axios.get("/api/inventoryorderdetailextend/selectInOrOutBoundId", {params: this.inventoryorderdetailextend}).then(res => {
                if (res.data.code === 1) {
                  this.$set(this.inventoryorderdetailextend,"order_id", '')
                  this.$set(this.inventoryorderdetailextend,"order_no", '')
                  toast.error(res.data.data)
                }else {
                  // alert(res.data.data.order_id)
                  this.$set(this.inventoryorderdetailextend,"order_id", res.data.data.order_id)
                }
              })
            }

        })
      }
    }

    const changeType = function(event) {
      this.inventoryorderdetailextend.type = event.value
    }

    const btKeyUp = function(e) {
      var reg = RegExp(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g);
      if(e.target.value.match(reg)){
        toast.error("包含特殊字符，入库数量自动取整!")
        // e.target.value = e.target.value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"");
        e.target.value = e.target.value.substring(0, e.target.value.indexOf("."))
        return
      }
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      onUploaded,
      blurInOutBoundNo,
      changeType,
      btKeyUp,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
    this.inventory_detailid = this.$route.query.inventory_detailid
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
